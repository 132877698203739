<template>
  <b-modal
      id="modal-delete-form-item"
      ref="modal-delete-form-item"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.deleteFormItem.title') }}</h3>
    </template>

    <template #default>
      <div class="delete-icon text-center">
        <b-icon icon="exclamation-triangle-fill" variant="danger" class="mt-5 mb-4"></b-icon>
        <div class="mt-2 mb-4">{{ $t('modals.deleteFormItem.message') }}</div>
      </div>
    </template>

    <template #modal-footer="{ close }">
      <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
          variant="primary"
          class="p-3"
          block
          @click="deleteFormItem"
      >
        {{ $t('modals.deleteFormItem.validate') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import DeleteForm from '@/graphQlQueries/mutations/forms/deleteForm';

export default {
  name: 'ModalDeleteFormItem',
  props: ['formItem'],
  methods: {
    async deleteFormItem() {
      try {
        await this.$apollo.mutate({
          mutation: DeleteForm,
          variables: {
            obj: {
              formId: this.formItem.form_id,
              name: this.formItem.name,
            },
          },
        });
        this.$emit('deleteFormItem');
        this.$refs['modal-delete-form-item'].hide();
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};
</script>

<style scoped>

</style>
